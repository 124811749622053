<template>
  <header class="page-header" ref="pageHeader">
    <div class="page-header__nav">
            <span class="logo" v-on:click="backToMain()" aria-label="Home" tabindex="0">
                <img src="@/assets/img/logo.svg" />
            </span>
      <div class="additional-items">
        <a :href="themeSettings.facebook" class="social-link social-link-facebook" target="_blank" rel="nofollow" aria-label="Facebook" tabindex="0">
          <img src="@/assets/img/icon-fb.svg" />
        </a>
        <a :href="themeSettings.instagram" class="social-link social-link-instagram" target="_blank" rel="nofollow" aria-label="Instagram" tabindex="0">
          <img src="@/assets/img/icon-insta.svg" />
        </a>
        <language-switcher/>
      </div>
    </div>
    <slider class="swiper-banner" role="banner"></slider>
  </header>
</template>
<script>
    import LanguageSwitcher from "../../menu/partials/LanguageSwitcher";
    import { i18n, defaultLocale } from '../../../main';
    import { mapState } from "vuex";
    import Slider from "./Slider";
    export default {
        name: "PageHeader",
        components: {
            Slider, LanguageSwitcher
        },
        methods: {
            backToMain() {
                //:to="navToHome()"
                if ( typeof this.pageHeader !== "undefined") {
                    if (this.$route.name !== `${i18n.locale}Home`) {
                        this.$router.push({ name: `${i18n.locale}Home`, replace: true})
                            .catch(()=>{});
                    }
                    this.$scrollTo( this.pageHeader, 400, {easing: "ease-in-out"});
                }
            },
            navToHome() {
                return {
                    name: `${localStorage.getItem('language') || defaultLocale }Home`,
                    replace: true
                }
            },
        },
        computed: mapState({
            themeSettings: state => state.themeSettings,
            pageHeader() {
                return this.$refs.pageHeader
            },
        }),
    }
</script>
<style scoped lang="scss">
  @import '../../../styles/utils/breakpoints';
  .page-header {
    margin: 0;
    @include md(){
      margin: 0 0;
    }
    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      cursor: pointer;
      display: block;
      width: 10rem;
      margin-right: 1rem;
      @include md(){
        width: 12rem;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .swiper-banner {
      width: calc( 100% - 4px );
      margin: 1.5rem auto 0;
      padding-bottom: 2rem;
      position: relative;
      overflow: hidden;
      .swiper-pagination {
        bottom: 0;
      }
    }
  }
  .additional-items {
    display: flex;
    align-items: center;
  }
  a.social-link {
    margin: 0.75rem 0.25rem 0;
    display: block;
  }
</style>
