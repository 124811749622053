<template>
  <div id="main-nav">
    <ul class="menu menu-main"
        :class="menuClass"
        ref="menuMain">
      <li v-for="(item, key) in itemMenu" :key="key"
          data-aos="fade">
        <menu-items
          :is-active="currentKey === item.key ? 'active' : ''"
          :is-main="true"
          :is-home="false"
          :item="item"
          @update-menu="updateMenu">
        </menu-items>
      </li>
    </ul>
    <div ref="menuSub">
      <ul class="menu menu-sub" v-if="subMenu.length > 0">
        <li v-for="(item, key) in subMenu" :key="key"
            data-aos="fade"
            :data-aos-delay="(key * 100)">
          <menu-items
            :is-main="false"
            :is-home="false"
            :item="item">
          </menu-items>
        </li>
        <li data-aos="fade"
            :data-aos-delay="(subMenu.length * 100)">
          <span class="btt-back-to" v-on:click="backToMain()">
              <div class="content"></div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import MenuItems from "./partials/MenuItems";
    import { i18n, defaultLocale } from '../../main';
    export default {
        name: "menuMain",
        components: {MenuItems},
        data: () => ({
            subMenu: [],
            currentKey: String,
            enableMobileMenu: false,
        }),
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            ...mapActions(['getMenu', 'unloadPage']),
            handleScroll () {
                this.enableMobileMenu = window.scrollY > 120 && this.$isMobile()
                    ? true
                    :false
            },
            updateMenu(e) {
                if (this.itemMenu.length > 0 && typeof e !== "undefined" ) {
                    this.unloadPage();
                    this.navigateToHomePage();
                    if (e === ''){
                        this.backToMain()
                    } else if (  e !== this.currentKey) {
                        this.currentKey = e;
                        this.setSubmenu();
                    }
                }
            },
            setSubmenu() {
                let refName = 'mainMenu';
                const subMenu = this.itemMenu.find( item => item.key === this.currentKey );
                if ( typeof subMenu !== "undefined") {
                    this.subMenu = typeof subMenu.below !== "undefined" && subMenu.below.length > 0 ? subMenu.below : [];
                } else {
                    this.subMenu = [];
                }
                if ( this.subMenu.length > 0) {
                    refName = 'menuSub';
                }
                const elMenu = this.$refs[refName];
                if ( typeof  elMenu !== "undefined") {
                    this.$scrollTo(elMenu, 400, {easing: "ease-in-out"});
                }
            },
            backToMain() {
                if ( typeof this.navMenu !== "undefined") {
                    this.navigateToHomePage()
                    this.subMenu = [];
                    this.currentKey = '';
                    this.$scrollTo( this.navMenu, 400, {easing: "ease-in-out"});
                }
            },
            navigateToHomePage() {
                if (this.$route.name !== `${i18n.locale}Home`) {
                    this.$router.push({ name: `${i18n.locale}Home`, replace: true})
                        .catch(()=>{});
                }
            }
        },
        computed: mapState({
            itemMenu: state => state.itemMenu,
            navMenu() {
                return this.$refs.menuMain
            },
            menuClass() {
                return `${(this.$isMobile() ? 'mobile' : 'desktop')} ${(this.enableMobileMenu ? ' mobile-active' : '')}`
            },
        }),
        mounted() {
            this.getMenu( localStorage.getItem('language') || defaultLocale ).then( response => {
                if( typeof response !== "undefined" && response.length > 0) {
                    if ( typeof this.$route.params !== "undefined") {
                        //this.slug = this.$route.params.slug;
                        if (this.$route.params.slug !== '') {
                            const current = this.itemMenu.find( item =>  (
                                typeof item.below !== 'undefined'
                                && item.below.filter( sub => sub.alias === this.$route.params.slug ).length > 0)
                            );
                            if ( typeof current !== "undefined") {
                                this.currentKey = current.key;
                                this.setSubmenu();
                            }
                        }
                    }
                }
            });
        },
        watch: {
            $route(to, form) {
                if ((form.name === 'plHome' && i18n.locale === 'en') ||
                    (form.name === 'enHome' && i18n.locale === 'pl')) {
                    this.getMenu( i18n.locale ).then( () => {
                        this.subMenu = [];
                        this.currentKey = '';
                    });

                }
            },
        }
    }
</script>
<style scoped lang="scss">
  @import '../../styles/common';
  $bg-color: #8c8f94;
  $gap: 3px;
  #main-nav {
    margin: 0 0 3rem;
    ul.menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      justify-items: stretch;
      align-items: stretch;
      &-main {
        background: $gray url('~@/assets/img/logo-white.svg') no-repeat center;
        background-size: contain;
      }
      &-sub {
        margin-top: $gap;
        li {
          background-color: $gray;
        }
      }
    }
    li {
      display: block;
      position: relative;
      width: 100%;
      margin: 0;
      padding-top: 100%; /* 1:1 Aspect Ratio */
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: $gap solid white;
        content: '';
      }
    }
    a, span {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-decoration: none;
      &.btt-back-to {
        @extend .btt-back-to;
      }
    }
  }
  @keyframes btt-pulse {
    from {
      background-position: 50% 50%;
    }
    to {
      background-position: 50% 45%;
    }
  }
</style>
