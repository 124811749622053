<template>
    <div class="content">
        <h5>{{title}}</h5>
        <span class="menu-item-desc">
            {{description}}
        </span>
    </div>
</template>

<script>
    export default {
        name: "MenuItemElement",
        props: ['title', 'description'],
    }
</script>

<style scoped lang="scss">
    @import '../../../styles/common';
    $gap: 2px;
    $padding: 10px;
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0.1rem;
        padding: $padding;
        //width: calc(100%  - (2 * #{$padding} + 3 * #{$gap}));
        //height: calc(100% - (2 * #{$padding} + 3 * #{$gap}));
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 1px transparent solid;
        text-align: left;
        @include transition(all 0.6s cubic-bezier(.18,.69,.65,.96) 0s);
        h5 {
            font-weight: 700;
            line-height: 105%;
            margin: 0;
            @include fontSize($font-head-small);
        }
        .menu-item-desc {
            line-height: 140%;
            font-weight: 800;
            position: relative;
            @include fontSize($font-head-xsmall);
        }
        @include md() {
            padding: $padding * 2;
            h5 {
                font-weight: 800;
                line-height: 110%;
                @include fontSize($font-head-xlarge * 0.7);
            }
            .menu-item-desc {
                @include fontSize($font-head-medium * 0.7);
            }
        }
        @include xl() {
            padding: $padding * 3;
            h5 {
                @include fontSize($font-head-xlarge);
            }
            .menu-item-desc {
                @include fontSize($font-head-medium);
            }
        }
        &.is-submenu {
            @include md() {
                h5 {
                    @include fontSize($font-head-large * 0.7);
                }
            }
            @include xl() {
                h5 {
                    @include fontSize($font-head-large);
                }
            }
        }
    }
</style>
