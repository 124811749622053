<template>
  <footer class="page-footer">
    <div class="page-footer__content">
      <div class="page-footer__logo-items">
        <a href="https://www.asp.krakow.pl" rel="nofollow" target="_blank" class="page-footer__logo-item">
          <img v-if="lang === 'pl'" src="@/assets/img/logo-asp-pl.svg" />
          <img v-else-if="lang === 'en'" src="@/assets/img/logo-asp-en.svg" />
        </a>
        <a href="https://waw.asp.krakow.pl" rel="nofollow" target="_blank" class="page-footer__logo-item">
          <img src="@/assets/img/logo-waw.svg" />
        </a>
      </div>
      <div class="content" v-html="themeSettings.footer_box"></div>
    </div>
    <div class="additional-items">
      <a :href="themeSettings.facebook" class="social-link social-link-facebook" target="_blank" rel="nofollow" aria-label="Facebook" tabindex="0">
        <img src="@/assets/img/icon-fb.svg" />
      </a>
      <a :href="themeSettings.instagram" class="social-link social-link-instagram" target="_blank" rel="nofollow" aria-label="Instagram" tabindex="0">
        <img src="@/assets/img/icon-insta.svg" />
      </a>
    </div>
    <vue-scroll-indicator
      height="5px"
      color="#c6c1bd"
      background="none">
    </vue-scroll-indicator>
  </footer>
</template>

<script>
    import { mapState } from "vuex";
    import { defaultLocale } from '../../../main'
    export default {
        name: "PageFooter",
        computed: mapState({
            themeSettings: state => state.themeSettings,
            lang() {
                return localStorage.getItem('language') || defaultLocale
            },
        }),
    }
</script>

<style scoped lang="scss">
  @import '../../../styles/common';
  ::v-deep {
    .v-scroll--indicator-wrapper {
      top: inherit !important;
      bottom: 0;
    }
  }
  .page-footer {
    margin: 2rem 0;
    text-align: right;
    &__logo-items {
      display: flex;
      align-items: center;
    }
    &__logo-item {
      display: block;
      margin-bottom: 1rem;
      max-width: 14rem;
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    &__content {
      .content {
        margin: 1rem 0;
        ::v-deep a {
          text-decoration: none;
          font-weight: 700;
          color: darken($gray, 20%);
        }
      }
      @include lg() {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>
