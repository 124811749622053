import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import VueScrollTo from 'vue-scrollto'
import AOS from 'aos'
import messages from './i18n/translations/pl.json'
import MasonryWall from '@yeger/vue2-masonry-wall'
//import LightGallery from 'vue-light-gallery'
import VueScrollIndicator from 'vue-scroll-indicator'
import VueMobileDetection from "vue-mobile-detection";
import VeeValidate from "vee-validate";

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


import 'aos/dist/aos.css'

Vue.config.productionTip = false;
Vue.use(AOS.init());
Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.use(MasonryWall);
//Vue.use(LightGallery);
Vue.use(VueScrollIndicator);
Vue.use(VueMobileDetection);
Vue.use(VeeValidate);
Vue.use(PerfectScrollbar)

export const defaultLocale = 'pl';
export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    pl: messages
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
