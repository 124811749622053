import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import { defaultLocale } from '../main'
import { loadLanguageAsync } from "../i18n/i18n-setup";

Vue.use(VueRouter)

function lazyLoad(view){
  return() => import(`@/components/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'plHome',
    component: Home
  },
  {
    path: '/en',
    name: 'enHome',
    component: Home
  },
  {
    path: '/:slug',
    name: 'plPage',
    component: lazyLoad('Page'),
    props: true
  },
  {
    path: '/en/:slug',
    name: 'enPage',
    component: lazyLoad('Page'),
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  loadLanguageAsync(localStorage.getItem('language') || defaultLocale)
      .then(() => next())
})

export default router
