<template>
  <div id="app" class="container">
    <page-header/>
    <main-menu/>
    <router-view/>
    <page-footer/>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { defaultLocale } from './main';
  import MainMenu from "./components/menu/MainMenu";
  import PageHeader from "./components/page/partials/PageHeader";
  import PageFooter from "./components/page/partials/PageFooter";
  export default {
    components: {
      PageFooter,
      PageHeader,
      MainMenu
    },
    computed: mapState({
      themeSettings: state => state.themeSettings,
    }),
    methods: {
      ...mapActions({
        getThemeSettings: 'getThemeSettings',
      }),
      navToHome() {
        return {
          name: `${localStorage.getItem('language') || defaultLocale }Home`,
          replace: true
        }
      },
    },
    mounted() {
      this.getThemeSettings( localStorage.getItem('language') || defaultLocale )
    }
  }
</script>

<style scoped lang="scss">
  @import "styles/common";
  @import "styles/typo";
  #app {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $gray;
    overflow: hidden;
    line-height: 180%;
  }
</style>
