<template>
  <picture>
    <source v-for="( image, imageIndex) in images" :key="imageIndex"
            :media="media(image.media)"
            :srcset="image.url">
    <img class="img-fluid w-100 mx-auto"
         :alt="title"
         :src="images[0].url">
  </picture>
</template>

<script>
    export default {
        name: "ResponsiveImage",
        props: ['title', 'images'],
        methods: {
            media( minWidth ) {
                return `(min-width: ${minWidth}px)`
            }
        }
    }
</script>
<style scoped lang="scss">
  img {
    max-width: 100%;
    height: auto;
    display: inline-block;
  }
</style>
