<template>
    <swiper
            ref="mainBannerSwiper"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            :loop="true"
            :autoplay="{
              delay: 4500,
              disableOnInteraction: false
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange">
        <swiper-slide v-for="(slide, dataIndex) in listSlides" :key="dataIndex">
            <div>
                <responsive-image
                        :title="slide.title"
                        :images="slide.images">
                </responsive-image>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import { defaultLocale } from '../../../main';
    import { Navigation, Pagination, Autoplay } from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
    import 'swiper/swiper-bundle.css'
    import ResponsiveImage from "../../partials/ResponsiveImage";
    SwiperCore.use([Navigation, Pagination, Autoplay])

    export default {
        name: "Slider",
        data: () => ({
            swiper: null
        }),
        components: {
            ResponsiveImage,
            Swiper,
            SwiperSlide,
        },
        methods: {
            ...mapActions(['fetchSlides']),
            onSwiper (swiper) {
                this.swiper = swiper
            },
            onSlideChange () {
                //console.log('slide change')
            }
        },
        computed: mapState({
            listSlides: state => state.listSlides,
            mainSwiper() {
                return this.$refs.mainBannerSwiper
            },
        }),
        mounted() {
            this.fetchSlides( localStorage.getItem('language') || defaultLocale ).then( response => {
                if (typeof response !== "undefined" && response.length > 0) {
                    this.swiper.update()
                }
            });
        },
    }
</script>

<style scoped lang="scss">
  @import '../../../styles/utils/breakpoints';
  ::v-deep {
    .swiper-pagination {
      bottom: 0.3rem;
      display: none;
      @include md(){
        display: block;
      }
      .swiper-pagination-bullet {
        width: 0.7rem;
        height: 0.7rem;
      }
      .swiper-pagination-bullet-active {
        background-color: #adadad
      }
    }
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .w-100 {
    width: 100%;
  }
  .ml-auto, .mx-auto {
    margin-left: auto;
  }
  .mr-auto, .mx-auto {
    margin-right: auto;
  }
</style>
