<template>
  <router-link v-if="isNav(item) && !isHome"
               :to="navTo(item)">
    <menu-item-element
      :class="[isMain ? 'is-main' : 'is-submenu']"
      :title="item.title"
      :description="item.description">
    </menu-item-element>
  </router-link>
  <span v-else-if="isNav(item) && isHome" class="is-home" v-on:click="extendMenu('')">
        <menu-item-element class="is-main"
                           :title="item.title"
                           :description="item.description">
        </menu-item-element>
    </span>
  <a v-else-if="!isNav(item)" v-on:click="extendMenu(item)"
     :class="isActive">
    <menu-item-element
      :class="[isMain ? 'is-main' : 'is-submenu', isActive]"
      :title="item.title"
      :description="item.description">
      {{isActive}}
    </menu-item-element>
  </a>
</template>

<script>
    import MenuItemElement from "./MenuItemElement";
    import { i18n } from '../../../main'
    export default {
        name: "MenuItems",
        components: {MenuItemElement},
        data: () => ({
        }),
        props: ['item', 'isMain', 'isHome', 'isActive'],
        methods: {
            extendMenu( item ) {
                this.$emit('update-menu', typeof item.key !== "undefined"
                    ? item.key
                    : '')
            },
            isNav(item) {
                return ( typeof item.uri !== "undefined")
            },
            navTo(item) {
                if ( typeof item.uri !== "undefined" ) {
                    if (item.uri === '') {
                        return {
                            name: `${i18n.locale}Home`,
                            /*path: i18n.locale === 'en'
                                ? '/en'
                                : '/',*/
                            replace: true
                        }
                    } else {
                        return {
                            name: `${i18n.locale}Page`,
                            params: {
                                slug: item.alias,
                                nid: parseInt(item.uri.split('/').pop()),
                            },
                            replace: true
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped lang="scss">
  @import '../../../styles/common';
  a, span {
    cursor: pointer;
    .content {
      &.is-main {
        color: $gray;
        opacity: 0;
      }
      &.is-submenu {
        color: $gray;
        background-color: white;
        border: 1px solid $gray;
      }
    }
    .mobile-active & {
      .content {
        &.is-main {
          opacity: 1;
          border-color: $gray;
          color: white;
          background-color: $gray;
        }
      }
    }

    &.active,
    &.router-link-active:not(.is-home),
    &:hover {
      .content {
        opacity: 1;
        border-color: $gray;
        &.is-main {
          background-color: white;
          color: $gray;
        }
        &.is-submenu {
          color: white;
          background-color: $gray;
        }
      }
    }
  }
</style>
