<template>
    <dropdown class="my-dropdown-toggle"
              :options="langArray"
              :selected="$i18n.locale"
              v-on:updateOption="methodToRunOnSelect"
              :placeholder="lang.name"
              :closeOnOutsideClick="true">
    </dropdown>
</template>

<script>
    import { mapActions } from "vuex";
    import dropdown from 'vue-dropdowns';
    import { defaultLocale } from '../../../main'
    export default {
        name: "LanguageSwitcher",
        components: {dropdown},
        data: () => ({
            langArray: [
                {name: 'pl'},
                {name:'en'}
            ],
            lang: {
                name: localStorage.getItem('language') || defaultLocale
            },
        }),
        methods:{
            ...mapActions({
                getThemeSettings: 'getThemeSettings',
                fetchSlides: 'fetchSlides'
            }),
            methodToRunOnSelect(payload) {
                this.lang = payload;
                localStorage.setItem('language',payload.name);
                this.$router.push({ name: `${payload.name}Home`, replace: true})
                    .catch(()=>{});
                this.getThemeSettings( payload.name )
                this.fetchSlides( payload.name )
            }
        }
    }
</script>

<style scoped lang="scss">
    .my-dropdown-toggle {
        margin-left: 1rem;
        min-width: 2.5rem !important;
        ::v-deep {
            .dropdown-menu {
              left: -1rem;
            }
            .dropdown-menu,
            .dropdown-toggle {
                min-width: 2rem !important;
            }
        }
    }
</style>
